<template>
  <v-container>
    <v-alert type="info" v-if="$vuetify.breakpoint.mdAndUp" prominent outlined dismissible>
      Öffne
      <b>WirKaufenBriefmarken.de</b> auf deinem Smartphone oder Tablet, um Bilder von deiner Sammlung gleich hier zu machen!
    </v-alert>
    <v-stepper flat v-model="current_step" vertical>
      <div
        class="ml-4 mt-4 mr-4"
      >Bitte beantworte die folgenden Fragen, um eine Anfrage zum Ankauf deiner Briefmarken an uns zu senden.</div>

      <v-stepper-step :complete="current_step > 1" step="1" :rules="[() => step1_valid]">
        <b>Wieviel?</b>
      </v-stepper-step>

      <v-stepper-content step="1">
        <v-form ref="step1_form" v-model="step1_valid" lazy-validation>
          <p>Wieviele Alben von Briefmarken hast du zum Verkaufen?</p>
          <v-text-field
            v-model="number_of_albums"
            :rules="number_of_albums_rules"
            prepend-inner-icon="mdi-book-multiple"
            append-icon="mdi-plus"
            label="Anzahl der Alben"
            @click:append="number_of_albums++"
            filled
            required
          ></v-text-field>
          <p>
            Lege deine Alben bitte auf ein Stapel und mache
            <strong>ein Foto</strong> davon.
          </p>
          <bilder-uploader ref="step1_uploader" maxCount="1" />
          <v-btn
            color="primary"
            :disabled="step1_valid == false"
            @click="step1_validate() && (current_step = 2)"
          >Weiter</v-btn>
        </v-form>
      </v-stepper-content>

      <v-stepper-step :complete="current_step > 2" step="2" :rules="[() => step2_valid]">
        <b>Einzelne Alben</b>
      </v-stepper-step>

      <v-stepper-content step="2">
        <v-form ref="step2_form" v-model="step2_valid" lazy-validation>
          <p>
            Bitte mache
            <b>3-5 oder mehr</b> Bilder von jedem Album. Lade die Bilder hier hoch.
          </p>
          <p>
            Fange bitte mit der
            <strong>ersten Seite</strong> an, danach ein paar Bilder
            <strong>in der Mitte</strong> des Albums, und zuletzt ergänze ein Bild der
            <strong>letzten Seite</strong>.
          </p>
          <div v-for="i in uploads" :key="i">
            <v-divider class="mb-4" />
            <p>
              Bilder des
              <strong>{{ i }}. Album</strong>:
            </p>
            <bilder-uploader
              :ref="`step2_uploader_${i}`"
              minCount="3"
              maxCount="10"
              :allowIncomplete="incompleteImagesAllowed"
            />
          </div>

          <div v-if="incompleteImagesPossible || incompleteImagesAllowed">
            <v-btn
              color="secondary"
              :disabled="incompleteImagesAllowed"
              small
              @click="incompleteImagesAllowed = true"
              class="mb-4"
            >{{ incompleteImagesAllowed ? "✔" : "" }} Keine weiteren Bilder hochladen</v-btn>
          </div>
          <v-btn
            color="primary"
            @click="step2_validate() && (current_step = 3)"
            :disabled="step2_valid == false"
          >Weiter</v-btn>
          <v-btn @click="current_step = 1" text>Zurück</v-btn>
        </v-form>
      </v-stepper-content>

      <v-stepper-step step="3" :rules="[() => step3_valid]">
        <b>Abschließende Informationen</b>
      </v-stepper-step>

      <v-stepper-content step="3">
        <v-form ref="step3_form" v-model="step3_valid" lazy-validation>
          <p>Willst du noch etwas über deine Briefmarken erzählen oder mitteilen?</p>
          <v-textarea
            v-model="extra_text"
            label="Zusätzliche Informationen über die Briefmarken"
            filled
          ></v-textarea>
          <p>Teile uns bitte deine Preisvorstellung mit.</p>
          <v-text-field
            v-model="price"
            :rules="price_rules"
            type="number"
            label="Preisvorstellung"
            prepend-inner-icon="mdi-currency-eur"
            filled
            required
          ></v-text-field>
          <p>Verrate uns eine Email-Addresse und/oder Telefonnummer, unter der wir dich erreichen können.</p>
          <v-text-field
            v-model="contact_info"
            :rules="contact_info_rules"
            type="text"
            label="Email und/oder Telefon"
            prepend-inner-icon="mdi-account-details"
            filled
            required
          ></v-text-field>
          <p>Überprüfe nochmal ob deine Angaben richtig sind, bevor du das Formular abschickst.</p>
          <v-card outlined color="grey lighten-3" class="d-flex align-center mb-4">
            <v-checkbox
              color="primary"
              :rules="toc_rules"
              class="ml-3"
              v-model="terms_and_conditions"
            >
              <template v-slot:label>
                <span>
                  Ich habe die
                  <a
                    href="#"
                    @click.stop="$emit('overlay', 'datenschutz')"
                  >Datenschutzrichtlinie</a> gelesen und akzeptiere diese.
                </span>
              </template>
            </v-checkbox>
          </v-card>
          <vue-recaptcha
            v-if="!captcha_disabled"
            sitekey="6LfvkbwUAAAAAInubNNEiDoMjCkAAaDcEz_YJxe-"
            language="de"
            loadRecaptchaScript
            @verify="(r) => {captcha_ok = true; captcha_response = r; }"
            @expired="() => {captcha_ok = false; captcha_response = null; }"
            class="mb-4"
          ></vue-recaptcha>
          <v-btn
            @click="step3_validate() && submit()"
            :disabled="!(captcha_ok || captcha_disabled) || !step3_valid"
            color="primary"
          >Anfrage abschicken</v-btn>
          <v-btn @click="current_step = 2" text>Zurück</v-btn>
        </v-form>
      </v-stepper-content>
    </v-stepper>

    <v-row justify="end">
      <v-btn small text @click="back">Zurück zur Startseite</v-btn>
    </v-row>

    <v-dialog persistent ref="submit_dialog" max-width="800" v-model="submit_overlay">
      <v-card>
        <v-card-title>Anfrage absenden</v-card-title>
        <v-card-text>
          <p v-if="!submit_error">{{ submit_text }}</p>
          <v-progress-linear
            v-if="!submit_error"
            height="32"
            rounded
            striped
            :value="submit_progress"
          />
          <v-alert prominent type="error" v-if="submit_error">
            <v-row align="center">
              <v-col class="grow">Beim Hochladen der Daten ist ein Fehler aufgetreten.</v-col>
              <v-col class="shrink">
                <v-btn @click="submit">Erneut versuchen</v-btn>
              </v-col>
            </v-row>
          </v-alert>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn color="primary" :disabled="!submit_complete" @click="$emit('finish')">
            <v-progress-circular v-if="!submit_complete" size="20" indeterminate color="primary" />
            <span v-if="submit_complete" @click="$emit('finished')">Beenden</span>
          </v-btn>
          <v-spacer />
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import VueRecaptcha from "vue-recaptcha";
import BilderUploader from "./uploader/BilderUploader";
import apiService from "../apiService";

import config from "../config";

export default {
  name: "Fragebogen",
  components: { VueRecaptcha, BilderUploader },
  data() {
    return {
      current_step: 1,

      step1_valid: true,
      step2_valid: true,
      step3_valid: true,

      number_of_albums: 1,
      number_of_albums_rules: [
        n => !!n || "Gebe bitte eine Anzahl ein.",
        n => parseInt(n, 10) == n || "Die Anzahl muss eine Zahl sein.",
        n => n > 0 || "Gebe bitte eine Zahl größer als Null ein.",
        n =>
          n < 50 ||
          "Wenn du 50 oder mehr Alben verkaufen möchtest, schreibe uns lieber direkt an, das wird hier sonst zu kompliziert."
      ],

      extra_text: "",

      price: "",
      price_rules: [
        n => !!n || "Gebe bitte einen Preis ein.",
        n => parseInt(n, 10) == n || "Der Preis muss eine Zahl sein.",
        n => n > 0 || "Gebe bitte einen Preis größer als Null ein.",
        n =>
          n < 1000000 ||
          "Wenn die Briefmarken echt so teuer sind, schreibe uns bitte lieber direkt an."
      ],

      contact_info: "",
      contact_info_rules: [
        n =>
          !!n ||
          "Gebe bitte mindestens eins von beidem ein, sonst können wir deine Anfrage nicht beantworten."
      ],

      captcha_ok: false,
      captcha_response: null,
      captcha_disabled: config.captchaDisabled,

      incompleteImagesPossible: false,
      incompleteImagesAllowed: false,

      terms_and_conditions: null,
      toc_rules: [
        n =>
          n === true ||
          "Du musst den Bedingungen zustimmen, sonst dürfen wir deine Anfrage nicht beantworten."
      ],

      submit_overlay: false,
      submit_text: "",
      submit_progress_total: 1,
      submit_progress_current: 1,
      submit_error: false,
      submit_complete: false,

      session_id: ""
    };
  },
  computed: {
    uploads: function() {
      return Array.from(
        { length: parseInt(this.number_of_albums) || 1 },
        (x, i) => i + 1
      );
    },
    submit_progress: function() {
      return (this.submit_progress_current / this.submit_progress_total) * 100;
    }
  },
  methods: {
    step1_validate() {
      const isValid = this.$refs.step1_uploader.validate() && this.$refs.step1_form.validate();
      if (isValid) {
        window._paq.push(['setCustomUrl', '/step1_complete']);
        window._paq.push(['trackPageView']);
      }
      return isValid;
    },

    step2_validate() {
      const enoughImagesUploaded =
        this.uploads
          .map(i => this.$refs[`step2_uploader_${i}`][0].validate())
          .reduce((x, y) => x && y) && this.$refs.step2_form.validate();
      this.incompleteImagesPossible = !enoughImagesUploaded;
      if (enoughImagesUploaded) {
        window._paq.push(['setCustomUrl', '/step2_complete']);
        window._paq.push(['trackPageView']);
      }
      return enoughImagesUploaded;
    },

    step3_validate() {
      const isValid = this.$refs.step3_form.validate();
      if (isValid) {
        window._paq.push(['setCustomUrl', '/step3_complete']);
        window._paq.push(['trackPageView']);
      }
      return isValid;
    },

    async submit() {
      try {
        this.submit_error = false;
        this.submit_complete = false;

        const images = this.collect_all_images();
        this.submit_overlay = true;
        this.submit_progress_total = images.length + 2;

        this.submit_text = "Hochladen der Daten beginnt...";
        this.submit_progress_current = 1;
        if (!this.session_id) {
          this.session_id = await apiService.initialize(this.captcha_response);
          if (!this.session_id) {
            this.submit_error = "failed obtaining a new session id";
            apiService.reportError(this.session_id, this.submit_error, {});
            return;
          }
        }

        for (let i = 0; i < images.length; i++) {
          const imageId = images[i][0];
          const imageFile = images[i][1];

          const ok = await apiService.upload(
            this.session_id,
            imageFile,
            imageId
          );
          if (!ok) {
            this.submit_error = `failed upload of ${imageId}`;
            apiService.reportError(this.session_id, this.submit_error, {});
            return;
          }

          this.submit_text = `Bilder hochgeladen: ${i + 1} / ${images.length}`;
          this.submit_progress_current = i + 2;
        }

        const ok = await apiService.finalize(
          this.session_id,
          this.build_finalize_data(images)
        );
        if (!ok) {
          this.submit_error = "failed finalization";
          apiService.reportError(this.session_id, this.submit_error, {});
          window._paq.push(['setCustomUrl', '/submission_error']);
          window._paq.push(['trackPageView']);
          return;
        }

        this.submit_progress_current = this.submit_progress_total;
        this.submit_complete = true;
        this.submit_text =
          "Hochladen der Bilder und Absenden der Anfrage abgeschlossen. " +
          "Wir werden deine Anfrage schnellstmöglich beantworten und uns unter deiner angegebenen Kontaktmöglichkeit melden. Vielen Dank!";

        window._paq.push(['setCustomUrl', '/submission_success']);
        window._paq.push(['trackPageView']);
      } catch (error) {
        this.submit_error = true;
        window._paq.push(['setCustomUrl', '/submission_error_catch']);
        window._paq.push(['trackPageView']);
        apiService.reportError(this.session_id, "caught axios error", error);
      }
    },

    collect_all_images() {
      const images = [];
      images.push(["all_albums", this.$refs.step1_uploader.getImages()[0]]);
      this.uploads
        .map(i => this.$refs[`step2_uploader_${i}`][0].getImages())
        .forEach((albumImages, albumIndex) => {
          albumImages.forEach((image, imageIndex) => {
            images.push([`album_${albumIndex + 1}_${imageIndex + 1}`, image]);
          });
        });
      return images;
    },

    build_finalize_data(images) {
      return {
        price: this.price,
        number_of_albums: this.number_of_albums,
        extra_text: this.extra_text,
        contact_info: this.contact_info,
        images: images.map(i => i[0])
      };
    },

    back() {
      if (
        confirm(
          "Willst du wirklich zur Startseite zurück? Deine Eingaben werden verworfen."
        )
      )
        this.$emit("back");
    }
  },

  mounted() {
    setTimeout(function() {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth"
      });

      window._paq.push(['setCustomUrl', '/submission_begin']);
      window._paq.push(['trackPageView']);
    }, 200);
  }
};
</script>