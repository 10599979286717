<template>
  <v-card :max-width="Math.min(window.width - 30, 1000)">
    <v-card-title>{{ title }}</v-card-title>
    <v-card-text :style="`overflow-y: auto; max-height: ${Math.min(window.height - 250, 800)}px;`">
      <slot></slot>
    </v-card-text>
    <v-card-actions class>
      <div class="flex-grow-1"></div>
      <v-btn @click="$emit('close')">Schließen</v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
export default {
  name: "overlay",
  props: {
    title: String
  },
  data: () => ({
    window: {
      width: 0,
      height: 0
    }
  }),
  created() {
    window.addEventListener('resize', this.handleResize)
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize)
  },
  methods: {
    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
    }
  }
};
</script>