<template>
  <v-app>
    <v-app-bar app dark color="primary">
      <v-container :pl-0="$vuetify.breakpoint.xs" :pr-0="$vuetify.breakpoint.xs">
        <v-toolbar-title class="headline text-uppercase">Wir kaufen Briefmarken</v-toolbar-title>
      </v-container>
    </v-app-bar>

    <v-content>
      <Landing v-if="landing" @continue="landing = false" :finished="finished" />
      <Fragebogen
        v-if="!landing"
        @overlay="(x) => { overlay = x; }"
        @finish="landing = true; finished = true;"
        @back="landing = true; finished = false;"
      />
      <v-overlay v-if="overlay">
        <overlay title="Impressum" v-if="overlay === 'impressum'" @close="overlay = false">
          <impressum />
        </overlay>
        <overlay title="Datenschutz" v-if="overlay === 'datenschutz'" @close="overlay = false">
          <datenschutz />
        </overlay>
      </v-overlay>
    </v-content>

    <v-footer app class="body-2" :class="$vuetify.breakpoint.xs ? 'pl-1 pr-1' : ''">
      <v-container pt-0 pb-0 :pl-0="$vuetify.breakpoint.xs" :pr-0="$vuetify.breakpoint.xs">
        <div class="d-flex flex-row flex-grow-1">
          <div>
            &copy; {{ new Date().getFullYear() }}
            Vitaliy Malov
          </div>
          <div class="flex-grow-1"></div>
          <div>
            <a href="#" @click.stop.prevent="overlay = 'impressum'">Impressum</a> |
            <a href="#" @click.stop.prevent="overlay = 'datenschutz'">Datenschutz</a>
          </div>
        </div>
      </v-container>
    </v-footer>
  </v-app>
</template>

<script>
import Fragebogen from "./components/Fragebogen";
import Overlay from "./components/Overlay";
import Impressum from "./components/overlays/Impressum";
import Datenschutz from "./components/overlays/Datenschutz";
import Landing from "./components/Landing";

export default {
  name: "App",
  components: {
    Fragebogen,
    Overlay,
    Impressum,
    Datenschutz,
    Landing
  },
  data: () => ({
    landing: true,
    overlay: false,
    finished: false
  })
};
</script>

<style lang="sass">
  @import '../node_modules/typeface-roboto/index.css'
</style>
