import Vue from 'vue';
import Vuetify, { colors } from 'vuetify/lib';
import '@mdi/font/css/materialdesignicons.css';

import de from 'vuetify/es5/locale'

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: 'mdi',
  },
  lang: {
    locales: { de },
    current: 'de'
  },
  theme: {
    themes: {
      light: {
        primary: colors.teal
      }
    }
  }
});
