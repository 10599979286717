<template>
  <v-container>
    <v-alert v-if="finished" type="success" prominent outlined border="left">
      Deine Anfrage wurde erfolgreich übermittelt. Vielen Dank!
      <br />Unsere Fachleute werden sich deine Sammlung schnellstmöglich ansehen und Kontakt mit dir aufnehmen.
    </v-alert>

    <v-carousel hide-delimiters :show-arrows="false" :height="$vuetify.breakpoint.xs ? 300 : 500">
      <v-carousel-item>
        <v-img class="fill-height" src="images/stamps1.jpg">
          <v-row
            align="end"
            class="white--text pt-2 pb-0 pl-4 pr-4 fill-height"
            style="margin: 0 !important"
            :class="$vuetify.breakpoint.xs ? 'lightboxXs' : 'lightbox'"
          >
            <v-col>
              <h1>Briefmarken für bares Geld verkaufen</h1>
              <p>Wandele deine Briefmarkensammlung in bares Geld um! Wir bieten dir einen fairen Preis und einen unkomplizierten Ablauf. Lege gleich los!</p>
            </v-col>
          </v-row>
        </v-img>
      </v-carousel-item>
    </v-carousel>

    <v-row justify="center">
      <v-btn
        rounded
        color="primary"
        :x-large="$vuetify.breakpoint.smAndUp"
        :large="$vuetify.breakpoint.xs"
        class="ma-6"
        @click="$emit('continue')"
        v-intersect="onIntersect"
      >
        <v-icon>mdi-currency-eur</v-icon>&nbsp;Jetzt Briefmarken verkaufen
      </v-btn>
    </v-row>

    <v-row justify="center">
      <v-col cols="12" sm="6" lg="4" align="center">
        <v-card>
          <v-card-text class="body-1">
            <v-icon size="128">mdi-timer</v-icon>
            <h2 class="ma-4">Schnell und unkompliziert</h2>
            <p class="ma-2" align="left">
              Du brauchst nur wenige Minuten, um deine Sammlung uns zum Verkauf anzubieten.
              Nur ein paar Eckdaten und Bilder deiner Sammlung werden benötigt,
              den Rest erledigen unsere Spezialisten!
            </p>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6" lg="4" align="center">
        <v-card>
          <v-card-text class="body-1">
            <v-icon size="128">mdi-playlist-check</v-icon>
            <h2 class="ma-4">So funktioniert's:</h2>
            <div align="left" class="ma-2">
              <ol>
                <li class="pb-2">Du gibst ein, wieviele Briefmarkenalben du hast</li>
                <li class="pb-2">Du lädst ein paar Bilder der Alben hoch</li>
                <li
                  class="pb-2"
                >Du teilst uns deinen Wunschpreis mit, und wie wir dich erreichen können (Email oder Telefon)</li>
                <li>Unsere Spezialisten melden sich in Kürze bei dir mit einem Angebot!</li>
              </ol>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6" lg="4" align="center" v-if="$vuetify.breakpoint.lgAndUp">
        <v-card>
          <v-card-text class="body-1">
            <v-icon size="128">mdi-cellphone-android</v-icon>
            <h2 class="ma-4">Nutze dein Mobilgerät!</h2>
            <p class="ma-2" align="left">
              Öffne
              <b>WirKaufenBriefmarken.de</b> auf deinem Smartphone oder Tablet. Dann kannst
              du die nötigen Bilder deiner Sammlung mit dem Gerät direkt in unserem Formular machen und
              hochladen. Einfacher geht's nicht!
            </p>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-container fixed bottom v-if="!isMainButtonVisible">
      <br />
      <v-row justify="center">
        <v-btn
          rounded
          color="primary"
          :x-large="$vuetify.breakpoint.smAndUp"
          :large="$vuetify.breakpoint.xs"
          class="ma-6"
          @click="$emit('continue')"
          elevation="6"
          fixed
          bottom
        >
          <v-icon>mdi-currency-eur</v-icon>&nbsp;Jetzt Briefmarken verkaufen
        </v-btn>
      </v-row>
    </v-container>
  </v-container>
</template>
<script>
export default {
  name: "landing",
  props: {
    finished: { default: false }
  },
  data: () => ({
    isMainButtonVisible: true
  }),
  methods: {
    onIntersect(entries) {
      this.isMainButtonVisible = entries[0].isIntersecting;
    }
  },
  mounted() {
    setTimeout(function() {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth"
      });
      window._paq.push(['setCustomUrl', '/landing']);
      window._paq.push(['trackPageView']);
    }, 200);
  }
};
</script>
<style>
.lightbox {
  box-shadow: 0 0 20px inset rgba(0, 0, 0, 0.2);
  background-image: linear-gradient(
    to top,
    rgba(0, 0, 0, 0.6) 0%,
    transparent 75%
  );
}
.lightboxXs {
  background-color: rgba(0, 0, 0, 0.4);
}
</style>