<template>
  <div class="mb-4">
    <div class="d-flex flex-row flex-wrap align-center justify-left">
      <thumbnail v-for="(file, i) in imagePreviews" :key="i" :file="file" @delete="deleteImage(i)" />

      <input
        ref="fileInput"
        accept="image/*"
        style="display:none"
        id="input-80"
        type="file"
        :multiple="maxCount > 1"
        @change="(x) => pushImages(x.target.files)"
      />

      <v-btn
        outlined
        color="grey"
        @click="$refs.fileInput.click()"
        width="120"
        height="120"
        class="upload mb-2"
        title="Bilder hinzufügen"
        v-if="imageFiles.length < maxCount"
      >
        <v-icon size="72">mdi-camera-plus</v-icon>
      </v-btn>
    </div>
    <div v-if="error" class="error--text"><p class="caption">{{ errorText }}</p></div>
  </div>
</template>

<script>
import Thumbnail from "./Thumbnail";

export default {
  name: "bilder-uploader",
  components: { Thumbnail },
  data: () => ({
    imageFiles: [],
    imagePreviews: [],
    error: false,
    errorText: ""
  }),
  props: {
    minCount: { default: 1 },
    maxCount: { default: 1 },
    allowIncomplete: { default: false }
  },
  watch: {
    allowIncomplete: function() {
      this.validate();
    }
  },
  methods: {
    pushImages(imageFiles) {
      Array.from(imageFiles).forEach(imageFile => {
        this.imageFiles.push(imageFile);
        var reader = new FileReader();
        reader.onload = e => {
          this.imagePreviews.push(e.target.result);
        };
        reader.readAsDataURL(imageFile);
      });

      this.$forceUpdate(); 
      this.validate();
    },
    deleteImage(i) {
      this.imageFiles.splice(i, 1);
      this.imagePreviews.splice(i, 1);

      this.$forceUpdate(); 
      this.validate();
    },
    validate() {
      if ((this.imageFiles.length < this.minCount) && !this.allowIncomplete) {
        this.error = true;
        this.errorText = `Bitte wähle mindestens ${this.minCount} Bild${
          this.minCount > 1 ? "er" : ""
        }.`;
      } else if (this.imageFiles.length > this.maxCount) {
        this.error = true;
        this.errorText = `Bitte wähle höchstens ${this.maxCount} Bild${
          this.maxCount > 1 ? "er" : ""
        }.`;
      } else this.error = false;
      this.$forceUpdate(); 
      return !this.error;
    },
    getImages() {
      return this.imageFiles;
    }
  }
};
</script>

<style>
.v-btn.upload {
  border-style: dashed !important;
}
</style>