import config from './config';
import axios from 'axios';

async function initialize(captchaResponse) {
    const result = await axios.post(`${config.apiBase}/initialize`, { captchaResponse });
    if (result.data && result.data.success && result.data.success === true && result.data.sessionId)
        return result.data.sessionId;
    else
        return false;
}

async function upload(sessionId, file, imageId) {
    const formData = new FormData();
    formData.append("imageFile", file);
    formData.append("imageId", imageId);
    formData.append("sessionId", sessionId);

    const result = await axios.post(`${config.apiBase}/upload`,
                                    formData,
                                    { headers: {
                                        'Content-Type': 'multipart/form-data'
                                    } });

    return result.data && result.data.success && result.data.success === true;
}

async function finalize(sessionId, data) {
    const result = await axios.post(`${config.apiBase}/finalize`, { sessionId, data });
    return result.data && result.data.success && result.data.success === true;
}

function reportError(sessionId, errorHint, error) {
    axios.post(`${config.apiBase}/error`, { sessionId, errorHint, error });
}

export default { initialize, upload, finalize, reportError };
