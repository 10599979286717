<template>
  <v-img
    :src="file"
    class="flex-grow-0 mr-2 mb-2 upload"
    width="120"
    aspect-ratio="1"
    @click="overlay = true"
    @mouseenter="overlay = true"
    @mouseleave="overlay = false"
  >
    <v-overlay absolute v-if="overlay">
      <v-btn icon outlined height="64" width="64" @click="$emit('delete')">
        <v-icon size="48">mdi-delete</v-icon>
      </v-btn>
    </v-overlay>
  </v-img>
</template>
<script>
export default {
  name: "thumbnail",
  props: {
    file: { default: {} }
  },
  data: () => ({
    overlay: false
  })
};
</script>
<style>
.v-image.upload {
  border-radius: 4px;
}
</style>